<template>
  <div style="padding-left:350px;padding-top:30px">
  
   
     <v-navigation-drawer
      v-model="drawer"
      absolute
      bottom
      
      
      width="320"
    >
    <div class="px-6">
  <h1>Kreditoren</h1>

   <p>
      <router-link :to="{ name: 'Costs', params: { tenant: current.path } }"
        >Fremdkosten-Übersicht</router-link
      >
    </p>
    </div>

        <v-list dense>
           <v-list-item-group >
          <v-list-item  exact-path :to="{name: 'Vendors', params: {tenant: current.path}}">
            <v-list-item-avatar>
              <v-icon inactive class="primary lighten-1 white--text"> mdi-cash-multiple </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Fremdkosten-Übersicht</v-list-item-title>

              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item exact-path :to="{name: 'Vendors', params: {tenant: current.path}}">
            <v-list-item-avatar>
              <v-icon class="primary lighten-1 white--text">
                mdi-exclamation-thick
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>ToDos</v-list-item-title>

              <v-list-item-subtitle>
                {{ todos.length }} Positionen ohne gültigen Kreditor
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-4"></v-divider>

          <v-list-item
            v-for="vendor in globalVendors"
            :key="vendor.id"
            :to="{
              name: 'SingleVendor',
              params: { tenant: current.path, id: vendor.id },
            }"
          >
            <v-list-item-avatar>
              <v-icon class="primary lighten-1" dark> mdi-receipt </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="vendor.name"></v-list-item-title>

              <v-list-item-subtitle>
                {{ vendor.type }} {{ vendor.account_number }}
              </v-list-item-subtitle>
            </v-list-item-content>

           
          </v-list-item>
           </v-list-item-group>
        </v-list>
     </v-navigation-drawer>
     
        <router-view></router-view>
        <v-layout v-if="!$route.params.id && vendors.length > 0">
          <table class="fk-zuweisung">
            <tbody>
              <tr v-for="item in todos" :key="item.fcid">
                <td>{{ item.division }}</td>
                <td>{{ item.client }}</td>
                <td>{{ item.title }}</td>
                <td style="width: 350px">
                  <v-text-field dense v-model="item.comment"></v-text-field>
                </td>
                <td class="text-right">{{ toLocale(item.value) }} T€</td>
                <td class="text-right">{{ item.vendor }}</td>
                <td>
                  <v-select
                    :items="globalVendors"
                    item-text="name"
                    item-value="id"
                    v-model="item.vendor_id"
                    outlined
                    hide-details
                    dense
                    height="1"
                    label="Kreditor zuweisen"
                  ></v-select>
                </td>
              </tr>
            </tbody>
          </table>
        </v-layout>
   

    <v-btn
      v-if="current.permissions.DIVISIONS_WRITE"
      color="primary"
      elevation="4"
      fab
      large
      :to="{
        name: 'SingleVendor',
        params: { tenant: current.path, id: 'new' },
      }"
      class="fab-new"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </div>
</template>

<script>
import { getDivisionName, getClientName, getAttributeName } from "@/utils/strings";
import { toLocale } from "@/utils/numbers";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      drawer: true,
      forecast: 0,
      costs: [],
    };
  },
  computed: {
    ...mapGetters([
      "current",
      "clients",
      "attributes",
      "divisions",
      "vendors",
      "forecasts",
    ]),
    globalVendors() {
      return this.vendors.filter((item) => item.divisions === null);
    },
    customVendors() {
      return this.vendors.filter((item) => item.divisions !== null);
    },
    todos() {
      return this.costs.filter((c) => c.vendor_id == 0 || !c.vendor_id);
    },
  },
  methods: {
    toLocale,
    getDivisionName,
    getClientName,
    getAttributeName,
    async fetchBudget() {
      this.loading = true;
      const params = {
        fcid: this.forecast.id,
        tenant: this.current.id,
      };

      const projects = await this.$store.dispatch("req", {
        route:
          "budget?" +
          Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&"),
      });

      this.loading = false;
      this.costs = [];
      projects
        .filter((p) => p.budget.costs !== null)
        .forEach((row) => {
          row.budget.costs.forEach((costItem) => {
            this.costs.push({
              fcid: row.budget.id,
              division: this.getDivisionName(row.division_id),
              client: this.getClientName(row.budget.client_id),
              title: row.budget.title,
              attribute: this.getAttributeName(row.budget.attribute_id),
              type: costItem.type,
              value: costItem.value,
              comment: costItem.comment,
              vendor: costItem.vendor,
              vendor_id: costItem.vendor_id,
            });
          });
        });
    },
  },
  created() {
    this.forecast = this.forecasts.filter((item) => item.active)[0];
    this.fetchBudget();
  },
};
</script>

<style lang="scss">
.fk-zuweisung {
  background-color: #fff;
  border-collapse: collapse;
  font-size:82%;
 
  td {
    padding: 0.25em .25em 0.25em 1em;
    border-bottom: 1px solid #ccc;
  }
}
</style>