var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-left":"350px","padding-top":"30px"}},[_c('v-navigation-drawer',{attrs:{"absolute":"","bottom":"","width":"320"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"px-6"},[_c('h1',[_vm._v("Kreditoren")]),_c('p',[_c('router-link',{attrs:{"to":{ name: 'Costs', params: { tenant: _vm.current.path } }}},[_vm._v("Fremdkosten-Übersicht")])],1)]),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"exact-path":"","to":{name: 'Vendors', params: {tenant: _vm.current.path}}}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"primary lighten-1 white--text",attrs:{"inactive":""}},[_vm._v(" mdi-cash-multiple ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fremdkosten-Übersicht")]),_c('v-list-item-subtitle')],1)],1),_c('v-list-item',{attrs:{"exact-path":"","to":{name: 'Vendors', params: {tenant: _vm.current.path}}}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"primary lighten-1 white--text"},[_vm._v(" mdi-exclamation-thick ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("ToDos")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.todos.length)+" Positionen ohne gültigen Kreditor ")])],1)],1),_c('v-divider',{staticClass:"my-4"}),_vm._l((_vm.globalVendors),function(vendor){return _c('v-list-item',{key:vendor.id,attrs:{"to":{
            name: 'SingleVendor',
            params: { tenant: _vm.current.path, id: vendor.id },
          }}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"primary lighten-1",attrs:{"dark":""}},[_vm._v(" mdi-receipt ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(vendor.name)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(vendor.type)+" "+_vm._s(vendor.account_number)+" ")])],1)],1)})],2)],1)],1),_c('router-view'),(!_vm.$route.params.id && _vm.vendors.length > 0)?_c('v-layout',[_c('table',{staticClass:"fk-zuweisung"},[_c('tbody',_vm._l((_vm.todos),function(item){return _c('tr',{key:item.fcid},[_c('td',[_vm._v(_vm._s(item.division))]),_c('td',[_vm._v(_vm._s(item.client))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',{staticStyle:{"width":"350px"}},[_c('v-text-field',{attrs:{"dense":""},model:{value:(item.comment),callback:function ($$v) {_vm.$set(item, "comment", $$v)},expression:"item.comment"}})],1),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.toLocale(item.value))+" T€")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.vendor))]),_c('td',[_c('v-select',{attrs:{"items":_vm.globalVendors,"item-text":"name","item-value":"id","outlined":"","hide-details":"","dense":"","height":"1","label":"Kreditor zuweisen"},model:{value:(item.vendor_id),callback:function ($$v) {_vm.$set(item, "vendor_id", $$v)},expression:"item.vendor_id"}})],1)])}),0)])]):_vm._e(),(_vm.current.permissions.DIVISIONS_WRITE)?_c('v-btn',{staticClass:"fab-new",attrs:{"color":"primary","elevation":"4","fab":"","large":"","to":{
      name: 'SingleVendor',
      params: { tenant: _vm.current.path, id: 'new' },
    }}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }